import { UserProducts } from '../../interfaces/products/takeBack'
import { ACTIONS } from '../actions/takeBack'

interface ReducerState {
  loading: boolean
  response?: UserProducts
  takeBackStock?: any
  withdrawalInfo?: any
  error?: any
}
export const initialTakeBackState: ReducerState = { loading: false }

const takeBackReducer = (
  state = initialTakeBackState,
  action
): ReducerState => {
  switch (action.type) {
    case ACTIONS.POST_TAKE_BACK_INFO_REQUEST:
    case ACTIONS.GET_TAKE_BACK_REFERENCE_REQUEST:
    case ACTIONS.GET_TAKE_BACK_ID_REQUEST:
    case ACTIONS.GET_TAKE_BACK_STOCK_REQUEST:
    case ACTIONS.GET_USER_TAKE_BACK_PRODUCTS_REQUEST:
    case ACTIONS.GET_RIGHT_OF_WITHDRAWAL_INFO_REQUEST:
      return { ...state, loading: true }
    case ACTIONS.POST_TAKE_BACK_INFO_SUCCESS:
    case ACTIONS.GET_TAKE_BACK_REFERENCE_SUCCESS:
    case ACTIONS.GET_TAKE_BACK_ID_SUCCESS:
    case ACTIONS.GET_USER_TAKE_BACK_PRODUCTS_SUCCESS:
      return { ...state, loading: false, response: action.response }
    case ACTIONS.GET_TAKE_BACK_STOCK_SUCCESS:
      return { ...state, loading: false, takeBackStock: action.response }
    case ACTIONS.GET_RIGHT_OF_WITHDRAWAL_INFO_SUCCESS:
      return { ...state, loading: false, withdrawalInfo: action.response }
    case ACTIONS.POST_TAKE_BACK_INFO_FAILURE:
    case ACTIONS.GET_TAKE_BACK_REFERENCE_FAILURE:
    case ACTIONS.GET_TAKE_BACK_ID_FAILURE:
    case ACTIONS.GET_TAKE_BACK_STOCK_FAILURE:
    case ACTIONS.GET_USER_TAKE_BACK_PRODUCTS_FAILURE:
    case ACTIONS.GET_RIGHT_OF_WITHDRAWAL_INFO_FAILURE:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}
export default takeBackReducer
