import React, { useEffect, useState } from 'react'
import type { AppProps } from 'next/app'
import { wrapper } from '../redux/store'
import { ThemeProvider } from '@material-ui/core'
import 'react-datepicker/dist/react-datepicker.css'
import 'swiper/swiper-bundle.min.css'
import 'glider-js/glider.min.css'
import useGlobalStyles from '../components/Global/Global.style'
import { SnackbarProvider } from 'notistack'
import InformationChip from '../components/InformationChip/InformationChip'
import { PageTransition } from 'next-page-transitions'
import Loader from '../components/Loader/Loader'
import { hotjar } from 'react-hotjar'
import dayjs from 'dayjs'
import { i18nextInit } from '../i18n.js'
import { useRouter } from 'next/router'
import { IKContext } from 'imagekitio-react'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import { DATADOG_KEY } from '../utils/constants'
import * as gtag from '../utils/gtag'

import { TRANSITION_TIMEOUT } from '../utils/utils'
import dynamic from 'next/dynamic'
import Script from 'next/script'
import { GAEvent } from '../typings/base'
import { PrismicPreview } from '@prismicio/next'
import useCookiebot from '../utils/useCookiebot'
import { CookiebotInterface } from '../interfaces/cookiebot/cookiebotInterface'
import { createInstance } from '@datapunt/matomo-tracker-react'
import { GoogleAnalytics } from '@next/third-parties/google'

const Theme = require(`../stores/${process.env.STORE}/theme.ts`)
const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)
import { PayPalScriptProvider } from '@paypal/react-paypal-js'

const InStoreManager = dynamic(() => import('../components/InStoreManager'))
const CssBaseline = dynamic(() => import('@material-ui/core/CssBaseline'))
const SSO = dynamic(() => import('../components/SSOManager'))
const CartManager = dynamic(() => import('../components/CartManager'))
const VersionManager = dynamic(() => import('../components/VersionManager'))
const Auth = dynamic(() => import('../components/AuthManager'))
const MatomoProvider = dynamic(() =>
  import('@datapunt/matomo-tracker-react').then((mod) => mod.MatomoProvider)
)
const Provider = dynamic(() =>
  import('next-auth/client').then((mod) => mod.Provider)
)
declare const window: any

const startDataDogMonitoring = function () {
  if (window.DD_STARTED) return
  datadogRum.init({
    applicationId: 'fef1c5dc-fc2f-4990-bb0e-63e42d993679',
    clientToken: DATADOG_KEY,
    site: 'datadoghq.eu',
    proxy: 'https://log-collector.lizee.io',
    service: 'rp-' + process.env.STORE,
    env: process.env.ENV,
    allowedTracingUrls: [
      function (url) {
        return (
          !url.includes('keycloak.') &&
          (url.includes('.lizee.io') || url.includes('http://localhost'))
        )
      },
    ],
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,

    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })
  datadogLogs.init({
    clientToken: DATADOG_KEY,
    site: 'datadoghq.eu',
    proxy: 'https://log-collector.lizee.io',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    env: process.env.ENV,
    service: 'rp-' + process.env.STORE,
  })
  window.DD_STARTED = true
}
const WlApp = ({ Component, pageProps, router }: AppProps) => {
  const nextRouter = useRouter()
  i18nextInit(nextRouter, pageProps.i18nResources)
  const isDisplayedBannerOnAllPages =
    pageProps?.banner?.data?.is_displayed_banner_on_all_pages
  const isHomePage = nextRouter.route === '/'
  const LayoutStyle = {
    isDisplayedBannerOnAllPages,
    isHomePage,
  }

  useGlobalStyles(LayoutStyle)

  useCookiebot()
  const [matomoInstance, setMatomoInstance] = useState(null)
  const [shouldAddGAScript, setShouldAddGAScript] = useState(false)
  const [shouldAddMatomoScript, setShouldAddMatomoScript] = useState(false)

  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.tracker(GAEvent.PAGE_VIEW, {
        page_location: url,
      })
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    // only start after first action to save money
    window.addEventListener('mousemove', startDataDogMonitoring)
    window.addEventListener('mousewheel', startDataDogMonitoring)

    window.addEventListener('CookiebotOnAccept', startMatomoAndGA)
    window.addEventListener('CookiebotOnDecline', removeMatomoAndGA)
  })

  const startMatomoAndGA = () => {
    const _mtm = (window._mtm = window._mtm || [])
    _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' })

    const matomoTracker = createInstance({
      urlBase: process.env.MATOMO_URL
        ? process.env.MATOMO_URL
        : 'https://analytics.lizee.io/',
      siteId: process.env.MATOMO_ID ? +process.env.MATOMO_ID : 4,
    })
    setMatomoInstance(matomoTracker)
    setShouldAddMatomoScript(true)

    if (typeof Cookiebot !== 'undefined') {
      const cookiebot = Cookiebot as unknown as CookiebotInterface
      if (cookiebot.consent.statistics) {
        _mtm.push({ event: 'cookie_consent_statistics' })
        setShouldAddGAScript(true)
      }

      if (cookiebot.consent.preferences) {
        _mtm.push({ event: 'cookie_consent_preferences' })
      }

      if (cookiebot.consent.marketing) {
        _mtm.push({ event: 'cookie_consent_marketing' })
      }
    }
  }

  const removeMatomoAndGA = () => {
    setMatomoInstance(null)
    setShouldAddMatomoScript(false)
    setShouldAddGAScript(false)
  }

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')

    // HOTJAR
    if (process.env.HOTJAR_ID) {
      hotjar.initialize(parseInt(process.env.HOTJAR_ID), 5)
    }

    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])

  const googleAnalyticsId = process.env.NEXT_PUBLIC_GA_ID
  const prismicURL = process.env.PRISMIC_URL
  const prismicRepo = prismicURL
    ? prismicURL.split('https://')[1].split('.cdn.prismic.io/api/v2')[0]
    : ''

  const initialPaypalOptions = {
    currency: 'EUR',
    clientId: process.env.PAYPAL_CLIENT_ID,
  }

  const App = () => (
    <Auth>
      <CartManager>
        <InStoreManager>
          <SSO>
            <VersionManager>
              <IKContext
                urlEndpoint={process.env.IMGKIT_URL} // Required. Default URL-endpoint is https://ik.imagekit.io/your_imagekit_id
                publicKey={process.env.IMGKIT_PUBLIC_KEY} // optional
                transformationPosition="path" // optional
              >
                <Component {...pageProps} />
              </IKContext>
            </VersionManager>
          </SSO>
        </InStoreManager>
      </CartManager>
    </Auth>
  )

  return (
    <React.Fragment>
      {shouldAddMatomoScript && (
        <Script
          async
          type="text/javascript"
          src={`https://analytics.lizee.io/js/container_${process.env.MTM_ID}.js`}
        />
      )}
      {shouldAddGAScript && googleAnalyticsId && (
        <GoogleAnalytics gaId={googleAnalyticsId} />
      )}
      <PrismicPreview repositoryName={prismicRepo}>
        <Provider session={pageProps.session}>
          <MatomoProvider value={matomoInstance}>
            <ThemeProvider theme={Theme.default}>
              <CssBaseline />
              <PageTransition
                key={router.route}
                timeout={TRANSITION_TIMEOUT}
                classNames="page-transition"
                loadingComponent={<Loader />}
                loadingDelay={500}
                loadingTimeout={{
                  enter: TRANSITION_TIMEOUT,
                  exit: 0,
                }}
                loadingClassNames="loading-indicator"
              >
                <SnackbarProvider
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  content={(key, message) => (
                    <InformationChip
                      key={key}
                      message={message}
                      i18n={pageProps.i18nResources.translations}
                    />
                  )}
                  autoHideDuration={3000}
                >
                  {process.env.PAYPAL_CLIENT_ID ? (
                    <PayPalScriptProvider options={initialPaypalOptions}>
                      <App />
                    </PayPalScriptProvider>
                  ) : (
                    <App />
                  )}
                </SnackbarProvider>
              </PageTransition>
            </ThemeProvider>
          </MatomoProvider>
        </Provider>
      </PrismicPreview>
    </React.Fragment>
  )
}

export default wrapper.withRedux(WlApp)
